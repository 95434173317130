<template>  
  <v-container style="max-width: 600px;">  
    <v-overlay v-model="showProgress" class="justify-center align-center">
      <v-progress-circular
        indeterminate
        size="60"
        width="5"
        color="primary"
      ></v-progress-circular>
    </v-overlay> 
    <password-dialog @password-verified="openPage"></password-dialog>
    <div>
      <v-card  class="mb-n6">  
        <v-card-text></v-card-text>
          <div ref="passage">
            <p class="mx-4 font-weight-regular">{{ currentPassage }}</p>  
          </div>
          <v-card-text class="font-weight-bold">{{ currentStorageName }} - {{ currentLevel }}</v-card-text>

      </v-card>  

    </div>
    <!-- AI Conversation  -->
    <v-row class="mt-5">
        <v-col>
          <v-card>  
          <v-card-text>  
            <v-list >  
              <v-list-item v-for="msg in currentMessagesAll" :key="msg.content">  
                <v-list-item-content>  
                  <v-list-item-title :class="{'pa-4 d-flex flex-row-reverse': msg.me}">                          
                    <v-chip
                      :color="msg.me ? 'deep-purple accent-4' : msg.tip ? 'blue accent-4' : 'blue darken-4'"
                      dark
                      style="height:auto;white-space: normal;"
                      class="pa-4"
                    >
                        {{ msg.content }}
                    </v-chip></v-list-item-title>  
                </v-list-item-content>  
              </v-list-item>  
            </v-list>  
          </v-card-text>  
 
        </v-card>  
        </v-col>
      </v-row>
      <v-row justify="center" v-if="reportissues.length > 1">  
        <v-btn color="primary" @click="prevIssue" :disabled="currentIndex === 0">Prev</v-btn>  
        <v-btn color="primary" class="mx-5" @click="nextIssue" :disabled="currentIndex === reportissues.length - 1">Next</v-btn>
        <v-btn dark color="black" class="mx-5" @click="currentIndex = reportissues.length - 1">Last</v-btn>
        <v-btn color ="yellow"> Total: {{ reportissues.length }}</v-btn>
      </v-row>
      <v-row justify="center">
        <v-btn class="mt-5" color="error" @click="del">Delete</v-btn>
      </v-row>
  </v-container>  
</template>  

<script>  
import PasswordDialog from '@/components/PasswordDialog.vue'; 

export default {  
  components: {  
      PasswordDialog,  
    },  
  data() {  
    return {  
      passage: "This is a story",
      question: "What is this?",
      itemLevels: ["1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th", "11th", "12th"],
      level: "12th",
      storageName: "HardMode",
      activeChat: 1,
      messagesAll: [
        {me: false, content: "First Content"},
        {me: true, content: "Second Content"}
      ],
      showProgress: false,
      reportissues: [],
      currentIndex: 0,
    }
  },
  created() {
    this.retrieveItems()
  },
  computed: {
    currentID(){
      return this.reportissues[this.currentIndex].id
    },
    currentLevel(){
      return this.reportissues[this.currentIndex].level
    },
    currentPassage(){
      return this.reportissues[this.currentIndex].passage
    },
    currentMessagesAll(){
      return this.reportissues[this.currentIndex].messagesAll
    },
    currentReqBodyMessages(){
      return this.reportissues[this.currentIndex].reqBodyMessages
    },
    currentStorageName(){
      return this.reportissues[this.currentIndex].storageName
    },
    currentDescription(){
      return this.reportissues[this.currentIndex].description
    }
  },
  methods: {
    openPage() {  
        this.pageOpen = true; // Open protected page if password is correct  
      },  
    async retrieveItems(){
      this.showProgress = true
        const query = `
          query reportissues(){
          reportissues(filter: { description: {eq: "Kyle"}}){
              items {
                description
                id
                level
                messagesAll
                passage
                reqBodyMessages
                storageName
              }
            }
          }`;
        const endpoint = "/data-api/graphql";
//kye
        try{
        const response = await fetch(endpoint, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({query})
        })
        const result = await response.json();
        this.reportissues = result.data.reportissues.items.map(item => ({
          id: item.id,
          level: item.level,
          messagesAll: JSON.parse(item.messagesAll),
          passage: item.passage,
          storageName: item.storageName
        }))

        this.showProgress = false

        } catch (err) {
          console.log(err)
        }
      },
      nextIssue() {
        this.currentIndex++  
      },  
      prevIssue() {  
        this.currentIndex--  
      },  
      async del() {
        const gql = `
          mutation del($id: ID!, $_partitionKeyValue: String!) {
            deletereportissue(id: $id, _partitionKeyValue: $_partitionKeyValue) {
              id
            }
          }`;

        const query = {
          query: gql,
          variables: {
            id: this.currentID,
          _partitionKeyValue: this.currentID
          }
        };

        const endpoint = "/data-api/graphql";
        const response = await fetch(endpoint, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(query)
        });

        const result = await response.json();
        console.log(`Record deleted: ${ JSON.stringify(result.data) }`);

        
        this.reportissues.splice(this.currentIndex,1)
        if(this.currentIndex===0){
          return;
        } else {
          this.currentIndex = this.currentIndex - 1
        }
        }
        
  }
}
</script>